
import { defineComponent, PropType, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Role } from "@/assets/ts/_utils/models/Role";
import ApiService from "@/core/services/ApiService";
import { User } from "@/store/models/user";
import useNotify from "@/core/composables/notify";
import { cloneDeep } from "lodash";
import { extractElementFormError } from "@/core/helpers/errors";

export default defineComponent({
  name: "add-user-modal",
  components: {},
  props: {
    roles: { type: Array as PropType<Role[]>, default: () => [] },
    initialData: { type: Object as PropType<User | null> },
  },
  emits: ["saved"],
  setup(props, { emit }) {
    const { toastErrorRaw, toastError } = useNotify();
    const formRef = ref<null | HTMLFormElement>(null);
    const addUserModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      first_name: props.initialData?.first_name || "",
      last_name: props.initialData?.last_name || "",
      email: props.initialData?.email || "",
      group: props.initialData?.groups?.[0]?.id || "",
    });

    const rules = ref({
      first_name: [
        {
          required: true,
          message: "First name is required",
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Last name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Invalid email",
          trigger: ["blur", "change"],
        },
      ],
      group: [
        {
          required: true,
          message: "Role is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid, fields: any) => {
        if (valid) {
          try {
            loading.value = true;
            if (props.initialData?.id) {
              await ApiService.patch(
                `company-users/${props.initialData.id}/`,
                formData.value as any
              );
            } else {
              await ApiService.post("company-users/", formData.value as any);
            }

            emit("saved");
            hideModal(addUserModalRef.value);
            formRef.value?.resetFields();
          } catch (error) {
            toastErrorRaw(error);
          } finally {
            loading.value = false;
          }
        } else {
          toastError({
            text: extractElementFormError(fields),
          });
          return false;
        }
      });
    };

    watch(
      () => props.initialData,
      (curVal, oldVal) => {
        console.log("curVal", curVal);
        if (curVal != oldVal && curVal) {
          formData.value = {
            first_name: curVal.first_name || "",
            last_name: curVal.last_name || "",
            email: curVal.email || "",
            group: curVal.groups?.[0]?.id || "",
          };
        }
      }
    );

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addUserModalRef,
    };
  },
});
