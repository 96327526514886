import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";

const useUsers = () => {
  const loading = ref<boolean>(false);

  const fetchUsers = async (params = {}, search = "") => {
    const { toastErrorRaw } = useNotify();

    try {
      loading.value = true;
      const resp = await ApiService.query("company-users/", {
        params,
        hideLoader: !!search,
      });
      return resp.data.results;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  return { loading, fetchUsers };
};

export default useUsers;
