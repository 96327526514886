
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";

import { debounce } from "lodash";
import useNotify from "@/core/composables/notify";
import useRoles from "@/core/composables/useRoles";
import useUsers from "@/core/composables/useUsers";

import { IDVerification } from "@/assets/ts/_utils/models/IDVerification";
import { useStore } from "vuex";
import AddUserModal from "../modals/forms/AddUserModal.vue";
import { User } from "@/store/models/user";
import { PERMS, hasPerms } from "@/core/helpers/permissions";
import { UserType } from "@/constants";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "users",
  components: {
    Datatable,
    AddUserModal,
  },
  setup() {
    const route = useRoute();
    const { toastErrorRaw, toastSuccess } = useNotify();
    const { roles, loading: loadingRoles, fetchRoles } = useRoles();
    const { loading: loadingUsers, fetchUsers } = useUsers();

    const store = useStore();

    const tableHeader = ref([
      {
        name: "User",
        key: "fullname",
        sortable: true,
      },
      {
        name: "Role",
        key: "user_type_display",
      },
      {
        name: "Last Login",
        key: "last_login",
      },
      {
        name: "Active",
        key: "is_active",
      },
      {
        name: "Date Joined",
        key: "date_joined",
      },

      {
        name: "Actions",
        key: "edit",
      },
    ]);

    const tableData = ref<Array<IDVerification>>([]);
    const tableKey = ref(1);
    const selectedRow = ref<User | null>(null);

    const fetchData = async (search?: string) => {
      try {
        tableData.value = [];

        const params: any = {};
        if (search) {
          params.search = search;
        }
        // check route option for usertype filter
        const { userType } = route.params || {};

        if (userType) {
          params.user_type = userType;
        }
        const resp = await fetchUsers(params);
        tableData.value = resp;
        setCurrentPageBreadcrumbs("User Management", ["Users List"]);
        tableKey.value = new Date().getTime();
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    const handleSaveData = async () => {
      await fetchData();
      toastSuccess({ text: "User saved successfully" });
      selectedRow.value = null;
    };

    const editUser = (user) => {
      selectedRow.value = user;
    };

    const revokeUser = (user) => {
      Swal.fire({
        text: `${user.first_name}'s access would be revoked`,
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Revoke Access",
        showCloseButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete(`company-users/${user.id}`)
            .then(() => {
              fetchData();
            })
            .catch((error) => {
              toastErrorRaw(error);
            });
        }
      });
    };

    const reinstateUser = (user) => {
      Swal.fire({
        text: `${user.first_name}'s access would be reinstated`,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Grant Access",
        showCloseButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.patch(`company-users/${user.id}/`, {
            is_active: true,
          } as any)
            .then(() => {
              fetchData();
            })
            .catch((error) => {
              toastErrorRaw(error);
            });
        }
      });
    };

    const getRolesQuery = () => {
      let q = "";
      const isAdminContext = route.path.includes("/admin");

      if (isAdminContext) {
        q += `?user_type=${UserType.ADMIN}&user_type=${UserType.REVIEWER}`;
      } else {
        q += `?user_type=${UserType.COMPANY_ADMIN}&user_type=${UserType.COMPANY_REVIEWER}`;
      }
      return q;
    };

    onMounted(async () => {
      await fetchData();

      // filter toles dependent on admin or nah
      const rolesQuery = getRolesQuery();
      await fetchRoles(rolesQuery);
    });

    const search = ref<string>("");
    const searchItems = debounce(() => {
      fetchData(search.value);
    }, 1000);

    const hasCreateUserPerm = computed(() =>
      hasPerms(store.getters.currentUser, [PERMS.ADD_USER])
    );

    watch(
      () => route.params,
      (curVal, oldVal) => {
        if (curVal != oldVal && store.getters.currentUser?.id) {
          fetchData();
        }
      }
    );

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      selectedRow,
      tableKey,
      roles,
      handleSaveData,
      loadingRoles,
      editUser,
      hasCreateUserPerm,
      authUser: store.getters.currentUser,
      loadingUsers,
      revokeUser,
      reinstateUser,
    };
  },
});
