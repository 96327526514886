import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";
import { Role } from "@/assets/ts/_utils/models/Role";

const useRoles = () => {
  const loading = ref<boolean>(false);
  const roles = ref<Role[]>([]);

  const fetchRoles = async (query = "") => {
    const { toastErrorRaw } = useNotify();

    try {
      loading.value = true;
      const resp = await ApiService.getWithoutSlug(`groups/${query}`);
      roles.value = resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    }
    loading.value = false;
  };

  return { roles, loading, fetchRoles };
};

export default useRoles;
